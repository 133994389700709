@import '../settings';

.menu {
  a:hover {
    color: $primary-color;
  }
}

nav {
  opacity: .9;
}

.hamburger,
.cross {
  font-size: 1.5rem;
  padding-right: 10px;
  padding-top: 10px;
}

.off-canvas-menu {
  background-color: $white;
  max-width: 50%;
  position: fixed;
  right: 0;
  z-index: 100;

  ul {
    margin: 0;
  }

  li {
    border-bottom: 1px solid $primary-color;
    list-style: none;
    padding: .7rem 1rem;
  }

  li:hover {
    background-color: lighten($primary-color, 15%);
  }
}
