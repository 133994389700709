@import '../settings';

@font-face {
  font-family: 'BonaNova';
  src: local('font'), url('../../fonts/BonaNova-Regular.ttf') format('ttf');
}

.real-estate-wrapper {
  align-items: center;
  background-image: url('../../images/House/white-indoor.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 5px solid $light-gray;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15rem map-get($grid-container-padding, small);
  position: relative;
  text-align: center;
  transition: background-image 1s ease-in-out;

  .real-estate-main-image {
    border: 5px solid $light-gray;
    padding: 1rem;
    transition: 1s ease-in-out;
  }

  .real-estate-text {
    background-color: rgb(0, 0, 0, .3); // scss-lint:disable ColorVariable
    color: $white;
    font-family: BonaNova;
    font-size: 2rem;
    padding: 1rem;
  }
}

header {
  align-items: center;
  background-image: url('../../images/House/white-indoor.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid $light-gray;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15rem map-get($grid-container-padding, small);
  position: relative;
  text-align: center;
  transition: background-image 1s ease-in-out;

  .real-estate {
    background-color: rgb(0, 0, 0, .3); // scss-lint:disable ColorVariable
    color: $white;
    font-family: BonaNova;
    font-size: 2rem;
    padding: 1rem;

    .name {
      font-size: 5rem;
    }

    p {
      opacity: 1;
    }
  }
}

.parallax {
  background-attachment: fixed;

  // Create the parallax scrolling effect
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  padding: 20rem map-get($grid-container-padding, small);
}

.houses-empty-state {
  font-size: 20px;
  padding: 1rem;
}

.my-projects {
  border-bottom: 2px solid $dark-gray;
}

.listingsLink {
  color: $primary-color;
}
