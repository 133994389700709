// eslint-disable IdSelector:0

@import './settings';

@each $name, $color in $button-palette {
  .#{$name}-color {
    color: get-color(#{$name});
  }
}

.root {
  background: $secondary-color;
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.bg-white {
  background-color: $white;
}

.black-color {
  color: $black;
}

.gradient {
  // eslint-disable-next-line ColorVariable
  background: linear-gradient(90deg, lighten($primary-color, 8%) 13%, lighten($primary-color, 10%) 63%, lighten($primary-color, 15%) 81%, lighten($primary-color, 20%) 100%);
}

.app-wrapper {
  margin-top: 70px;
}

.dark-grey-color {
  color: $dark-gray;
}

a {
  cursor: pointer;
}
