@import '../settings';

// The actual timeline (the vertical ruler)
.timeline {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}

// The actual timeline (the vertical ruler)
.timeline::after {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 10px 27px 3px rgb(204 204 204 / 20%);
  content: '';
  left: 50%;
  margin-left: -3px;
  position: absolute;
  top: 0;
  width: 6px;
}

// Container around content
.container {
  background-color: inherit;
  padding: 10px 40px;
  position: relative;
  width: 50%;
}

// The circles on the timeline
.container::after {
  background-color: get-color(info);
  border: 4px solid $white;
  border-radius: 50%;
  box-shadow: 0 10px 27px 3px rgb(204 204 204 / 20%);
  content: '';
  height: 25px;
  position: absolute;
  right: -13px;
  top: 15px;
  width: 25px;
  z-index: 1;
}

// Place the container to the left
.left {
  left: 0;
}

// Place the container to the right
.right {
  left: 50%;
}

// Add arrows to the left container (pointing right)
.left::before {
  border: medium solid $white;
  border-color: transparent transparent transparent $white;
  border-width: 10px 0 10px 10px;
  content: ' ';
  height: 0;
  position: absolute;
  right: 30px;
  top: 22px;
  width: 0;
  z-index: 1;
}

// Add arrows to the right container (pointing left)
.right::before {
  border: medium solid $white;
  border-color: transparent $white transparent transparent;
  border-width: 10px 10px 10px 0;
  content: ' ';
  height: 0;
  left: 30px;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
}

// Fix the circle for containers on the right side
.right::after {
  left: -12px;
}

// The actual content
.content {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 10px 27px 3px rgb(204 204 204 / 20%);
  padding: 20px 30px;
  position: relative;
}

// Media queries - Responsive timeline on screens less than 600px wide
@media screen and (max-width: 600px) {
  // Place the timelime to the left
  .timeline::after {
    left: 31px;
  }

  // Full-width containers
  .container {
    padding-left: 70px;
    padding-right: 25px;
    width: 100%;
  }

  // Make sure that all arrows are pointing leftwards
  .container::before {
    border: medium solid $white;
    border-color: transparent $white transparent transparent;
    border-width: 10px 10px 10px 0;
    left: 60px;
  }

  // Make sure all circles are at the same spot
  .left::after,
  .right::after {
    left: 15px;
  }

  // Make all right containers behave like the left ones
  .right {
    left: 0%;
  }
}
